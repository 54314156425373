import React from 'react';
import {Table} from 'reactstrap';

const Home = (home) => (
    <tr>
        <td className="align-middle text-nowrap">
            <a href={"https://www.greathomes.org/"} target="_blank" className="text-decoration-none">{home._source.devName} <i className="fa fa-angle-right"></i></a>{home._source.dbaName}
        </td>
        <td className="align-middle text-nowrap">
            {home._source.salesBldngType}
        </td>
        <td className="align-middle text-nowrap">
            {home._source.salesPriceRange}
        </td>
        <td className="align-middle text-nowrap">
            {home._source.sellingSqftRange}
        </td>
        <td className="align-middle text-nowrap">
            {home._source.address}
        </td>
    </tr>
  )

const HomeList = ({ homes }) => (
    <Table responsive striped className="mb-0">
        <thead>
            <tr>
                <th className="bt-0">楼盘</th>
                <th className="bt-0">房型</th>
                <th className="bt-0">价格</th>
                <th className="bt-0">面积</th>
                <th className="bt-0">地址</th>
            </tr>
        </thead>
        <tbody>
            {homes.map(home => (
                <Home key={home._source.devName} {...home} />
            ))}
        </tbody>
    </Table>
)

export { HomeList };
