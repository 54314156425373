import React from "react"
import HeaderStyle1 from "../components/Header/HeaderStyle1"
import SectionTitle from "../components/SectionTitle/SectionTitle"
import FooterStyle1 from "../components/Footer/FooterStyle1"
import {Helmet} from "react-helmet";

import {HomeList} from "../components/HomeList"

const NewHomes = (props) => {
  const {pathContext} = props;
  const homes = pathContext.homes;

  return (
    <>
      <HeaderStyle1 />

      <div className="application">
          <Helmet>
              <meta charSet="utf-8" />
              <title>湾区新房解析 | Jing Xue | 湾区置业专家 湾区Top华人房产经纪 | 熟悉南湾、东湾、三谷市场 | Remax Accord 排名第一 经纪人 | 近12个月成交量超1亿美金</title>
              <meta name="description" content="一支秉持诚信、负责、专业的湾区TOP 1% 房产经纪人团队，熟悉南湾、东湾、三谷市场，全面考虑客户利益，帮客户选择最适合的房子，专业团队的一条龙服务给客户带来轻松高效的房产交易体验！团队提供房屋贷款、房屋装修、staging、清洁等服务" />
              <meta name="Keywords" content="湾区学校排名,湾区公立高中排名,湾区学区,华人房产经纪,南湾,东湾,三谷,地产经纪,pleasanton,fremont,cupertino,san jose,mountain view,dublin,palo alto,melo park,livermore,dublin,danville"></meta>
              <meta name="author" content="Jing Xue" />
              <link rel="canonical" href="https://www.jingxuehomes.com" />
          </Helmet>
      </div>
      {/* Start Feature Section */}
      <section
        className="pad-t30 pad-b30 parallax"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title="2021年湾区新房盘点及价格分析" />
            </div>
          </div>
          <div className="row">
              <HomeList homes={homes} />
          </div>
        </div>
      </section>
      {/* End Feature Section */}
      {/* Start Footer Section*/}
      <FooterStyle1 />
      {/* End Footer Section*/}
    </>
  )
}

export default NewHomes
